import axios, { AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "sonner";
import { getDeviceId, getDeviceType } from "../lib/utils";

const Config = (): AxiosRequestConfig => {
  const profileItem = localStorage.getItem("profile");
  const profile: Profile | null = profileItem ? JSON.parse(profileItem) : null;
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("Authorization") || "",
      "X-Email-Id": profile ? profile.Emailid : undefined,
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      DeviceType: "web",
      DeviceId: "123",
      Lat: "1",
      Long: "1",
    },
    data: {},
  };
};

export const SendRequestOTPService = async (body: EmailCheckRequestBody) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckEmail_Send_OTP`,
      body,
      Config() // Assuming Config() is defined elsewhere and its return type is compatible with AxiosRequestConfig
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("SendRequestOTPService: ", error);
  }
};

export const LoginByOTPService = async (body: EmailOTPCheckRequestBody) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/Check_Login`,
      body,
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("LoginByOTPService: ", error);
  }
};

export const CheckGoogleLogin = async (body: GoogleLoginCheckRequestBody) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckSocialUser`,
      body,
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("CheckGoogleLogin: ", error);
  }
};

export const CheckUserEmailIsRegistered = async (email: string) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/Auth/CheckUser_Emailid?Emailid=${email}`,
      Config()
    );
    const resp_1 = resp.data;
    return resp_1;
  } catch (error) {
    console.error("CheckUserEmailIsRegistered: ", error);
  }
};

export const RegistrationByOTPService = async (
  body: RegistrationByOTPRequestBody
) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/Auth/Registration_V4`,
      body,
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("RegistrationByOTPService: ", error);
  }
};

export const OODOCreateUserV2Service = async (
  body: OdooCreateUserV2ServiceBody
) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/create-user/v2`,
      body,
      Config()
    );
    const response = resp?.data;
    //console.log("resp_1", response)
    //const response = jwtDecode(resp.data);
    return response;
  } catch (error) {
    console.error("OODOCreateUserV2Service: ", error);
  }
};

export const GetCountryList = async () => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/country-list`,
      {},
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("GetCountryList: ", error);
  }
};

export const GetTimezone = async (body: TimezoneRequestBody) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}new-uwc-jwt/country-timezone`,
      body,
      Config()
    );
    const resp_1 = resp.data;
    if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
    return resp_1;
  } catch (error) {
    console.error("GetTimezone: ", error);
  }
};

export const CheckReferralCode = async (
  referralCode: string | null | undefined
) => {
  try {
    if (referralCode !== null && referralCode !== undefined) {
      const resp = await axios.get(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/verify-referral-code/${referralCode}`,
        Config()
      );
      // console.log("resp", resp);
      return resp.data;
    }
  } catch (error) {
    console.error("CheckReferralCode: ", error);
  }
};

// NEW SANDBOX APIS

export const CheckUserExists = async (
  email: string | null | undefined,
  type?: string | null
) => {
  try {
    if (email !== undefined && email !== null) {
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/exist`,
        { email, type },
        Config()
      );
      return resp?.data;
    }
  } catch (error) {
    console.error("CheckUserExists: ", error);
  }
};

export const GenerateOTP = async (
  email: string | null | undefined,
  host: any
) => {
  try {
    if (email !== null && email !== undefined) {
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}auth/otp/generate`,
        { email, host },
        Config()
      );
      // console.log("resp", resp);
      return resp;
    }
  } catch (error) {
    console.error("GenerateOTP: ", error);
  }
};

export const VerifyOTP = async (
  email: string | undefined | null,
  otp: number
) => {
  try {
    if (email !== null && email !== undefined) {
      const resp = await axios.post(
        `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}auth/otp/verify`,
        { email, otp },
        Config()
      );
      return resp;
    }
  } catch (error) {
    console.error("VerifyOTP: ", error);
  }
};

export const RegisterCopilotUser = async (body: any) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/register/copilot`,
      body,
      Config()
    );
    return resp;
  } catch (error) {
    console.error("RegisterCopilotUser: ", error);
  }
};

export const RegisterUserJuggad = async (body: any) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/user/register`,
      body,
      Config()
    );
    return resp;
  } catch (error) {
    console.error("RegisterUser New: ", error);
    throw new Error("RegisterUser New API error: " + error);
  }
};

export const RegisterCopilotUserJuggad = async (body: any) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/copilot/register`,
      body,
      Config()
    );
    return resp;
  } catch (error) {
    console.error("Register Copilot Expert New: ", error);
  }
};

export const GetSpecializationTypes = async () => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/specialization/fetch`,
      Config()
    );
    return resp;
  } catch (error) {
    console.error("GetSpecializationTypes: ", error);
  }
};

export const GetLocationDetails = async () => {
  try {
    const resp = await axios.get(`https://geoip.unitedwecare.com`, {
      headers: {
        "x-api-key": "gvZ3L7G5ToQb5F4Dre",
      },
    });
    //console.log("resp", resp);
    return resp;
  } catch (error) {
    console.error("GetSpecializationTypes: ", error);
    throw new Error("GetLocationDetails error: " + error);
  }
};

export const GetUserRole = async (userID: string | number) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/get_role/${userID}`,
      {
        headers: {
          "x-api-key": "gvZ3L7G5ToQb5F4Dre",
        },
      }
    );
    //console.log("resp", resp);
    return resp?.data?.data;
  } catch (error) {
    console.error("GetSpecializationTypes: ", error);
  }
};

export const validateToken = async (token: string) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_BASE_URL}api/sso/validate_token?token=${token}`,
    Config()
  );
  const resp_1 = resp.data;
  if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
  return resp_1;
};

export const SSOAuthLogin = async (
  body: any,
  type: string,
  socialAuth: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_NEW_REGISTRATION_BASE_URL}users/${type}/social_auth/${socialAuth}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Lat: "us-lat",
          Long: "us-long",
          DeviceId: getDeviceId(),
          DeviceType: getDeviceType(),
        },
      }
    );

    return response?.data;
  } catch (error) {
    console.error("CheckGoogleLogin: ", error);
  }
};
