import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import {
  CheckGoogleLogin,
  CheckReferralCode,
  CheckUserEmailIsRegistered,
  GetCountryList,
  GetTimezone,
  LoginByOTPService,
  RegistrationByOTPService,
  SendRequestOTPService,
} from "../api/_request";
import { toast } from "sonner";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

import CustomModal from "../components/CustomModal";

import { BotMessageSquare, Loader2, User } from "lucide-react";
import OTPInput from "react-otp-input";
import UWCLOGO from "../assets/img/Uwc_logo2.webp";
import UserRegisterForm from "../components/UserRegisterForm";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import ExpertRegisterForm from "../components/ExpertRegisterForm";
import { Button } from "@headlessui/react";
import LoginButton from "../components/buttons/loginButton";
import { useAppState } from "../context/AppStateContext";

const regJSON = [
  {
    _id: 1,
    type: "USER",
    title: "User",
    description: "I'm here to connect, learn, and share",
    image: User,
  },
  {
    _id: 2,
    type: "COPILOT",
    title: "Co-Pilot Expert",
    description: "I'm here to guide and assist",
    image: BotMessageSquare,
  },
];

const regAskSamJSON = [
  {
    _id: 1,
    type: "USER",
    title: "Patient",
    description: "I'm here to connect, learn, and share",
    image: User,
  },
  {
    _id: 2,
    type: "COPILOT",
    title: "Co-Pilot for Clinicians",
    description: "I'm here to generate patient notes",
    image: BotMessageSquare,
  },
];

const Register = () => {
  const { state } = useAppState();

  const [detailModalShow, setDetailModalShow] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataInterface>({
    email: "",
    fullName: "",
    firstName: "",
    lastName: "",
    title: "`",
    referralCode: "",
    selectedCountry: "",
    selectedTimezone: "",
    isTermsAccepted: false,
    socialID: null,
    socialType: null,
    mobileNo: "",
    userType: 4,
    countryID: "",
    userId: "",
    institutionId: "",
    referenceId: "",
    groupId: "",
    device_type: "",
    device_id: "",
  });
  const [countries, setCountries] = useState<any>([]);
  const [timezoneList, setTimezoneList] = useState<any>(null);
  const [disableRegister, setDisableRegister] = useState<boolean>(true);
  const [isOTPServiceCalled, setIsOTPServiceCalled] = useState<boolean>(false);
  const [isRegisterServiceCalled, setIsRegisterServiceCalled] =
    useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [referralCodeErr, setReferralCodeErr] = useState<string>("");
  const [otpErr, setOtpErr] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [counter, setCounter] = useState<number>(30);

  const [registrationType, setRegistrationType] = useState<null | string>();

  const navigate = useNavigate();
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");

  //Resend OTP counter
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (step === 1) {
      if (counter > 0) {
        timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
      } else if (counter === 0) {
        //setResendAllowed(true); // Enable resend button when countdown reaches 0
      }
    }
    return () => clearTimeout(timer);
  }, [step, counter]);

  //UseEffects that should run only once
  useEffect(() => {
    getCountryList();
  }, []);

  //UseEffect for setting Mobile number and call timezone list
  useEffect(() => {
    if (formData.selectedCountry === "IN") {
      setFormData((prevData: any) => ({
        ...prevData,
        mobileNo: "+91 0000000000",
      }));
    } else if (formData.selectedCountry === "CA") {
      setFormData((prevData: any) => ({
        ...prevData,
        mobileNo: "+1 0000000000",
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        mobileNo: "+00 0000000000",
      }));
    }
    if (formData.selectedCountry === "IN") {
      setDisableRegister(false);
    } else if (
      formData.selectedCountry !== "" &&
      formData.selectedTimezone !== ""
    ) {
      setDisableRegister(false);
    }

    if (formData.selectedCountry !== "IN" && formData.selectedCountry !== "") {
      getTimezoneList();
    }
  }, [formData.selectedCountry]);

  const isAskSam =
    window.location.host.includes(".ask-sam.ai") ||
    window.location.host.includes(".asksam.com.au");

  const formattedRegJson = isAskSam ? regAskSamJSON : regJSON;

  //console.log("registrationType", registrationType);

  const checkIfEmailExists = async (emailID: string) => {
    //console.log("formData", formData)
    try {
      const res = await CheckUserEmailIsRegistered(emailID);

      if (res?.ResponseCode === 200) {
        //Email not yet registered
        return false;
      } else if (res?.ResponseCode === 400) {
        //Email already exists
        return true;
      }
      //console.log("checkIfEmailExists", res);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const onGoogleLoginSuccess = async (credentialResponse: any) => {
    //console.log("Login Success:", credentialResponse);
    try {
      // Ensure jwtDecode is called safely
      const { email, name } = jwtDecode<DecodedToken>(
        credentialResponse?.credential
      );
      //console.log("decodedToken", email, name);

      if (!email || !name) {
        throw new Error("Token decoding failed");
      }

      setFormData((prevData) => ({
        ...prevData,
        email,
        fullName: name,
        isTermsAccepted: true,
      }));

      const isEmailAlreadyRegistered = await checkIfEmailExists(email);

      //Check with condition !isEmailAlreadyRegistered in local if using the email of registered user
      if (isEmailAlreadyRegistered) {
        //Email exists try logging in instead
        toast.warning(
          "This email is already registered. Please try logging in!"
        );
      } else {
        //Continue to register
        setDetailModalShow(true);
      }
    } catch (error) {
      console.error("Error in Google login process:", error);
      toast.error("Oops!! Please try again");
      // Handle any errors that occurred during the login process
    }
    //setCredentialCode(credentialResponse?.credential); // Set the credential code received
  };

  const getCountryList = async () => {
    try {
      const res = await GetCountryList();

      //console.log("LIST", res.data.countries);
      setCountries(res.data.countries);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const getTimezoneList = async () => {
    const reqBody = {
      code: formData?.selectedCountry,
    };
    try {
      const res = await GetTimezone(reqBody);

      //console.log("LIST", res.data.timezones);
      setTimezoneList(res.data.timezones);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const handleFormData = (
    field: string,
    value: string | number | null | boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleNextRegister = () => {
    const params = window.location.href.split("?")[1];
    const hasRedirectURL =
      redirectURL !== null && redirectURL !== undefined && redirectURL !== "";
    const navigateWithParams = (path: string) =>
      navigate(`${path}${hasRedirectURL ? `?${params}` : ""}`);

    switch (registrationType) {
      case "USER":
        navigateWithParams("/register/user-register");
        break;
      case "COPILOT":
        navigateWithParams("/register/copilot-register");
        break;
      default:
        console.error("INVALID REGISTER TYPE!!");
    }
  };

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.48)` // Adjust the alpha value as needed
    : "";

  return (
    <>
      <div className="w-full max-w-[355px] px-1  Login--form ">
        <LoginButton redirectURL={redirectURL} type={"FLOATING"} />

        <img
          src={state?.appLogoUrl || ""}
          alt="logo"
          width={180}
          className="mx-auto mb-4 xl:hidden"
        />
        <h1 className="text-2xl font-semibold tracking-tight text-center">
          Create Account
        </h1>
        {/* <p className="text-sm text-slate-500 font-medium text-center">
          Sign up with your email address
        </p> */}
        <h6 className="mt-1 text-center text-base">
          Select how you want to register as
        </h6>

        <div className="grid gap-3 mt-6">
          {formattedRegJson?.map((item: any) => {
            return (
              <div
                key={item?._id}
                className={`p-3 rounded-lg border border-gray-300 flex items-start gap-2 cursor-pointer hover:bg-gray-50 ${
                  registrationType == item.type
                    ? "ring-2 ring-offset-2 ring-violet-500"
                    : ""
                }`}
                onClick={() => setRegistrationType(item.type)}
              >
                <div className="p-1.5 bg-blue-100 rounded-md text-slate-600">
                  <item.image className="w-5 h-5" />
                </div>
                <div className="">
                  <p className="font-semibold">{item.title}</p>
                  <p className="text-sm">{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>

        <button
          style={{
            backgroundColor: !registrationType ? rgbaColor : "var(--primary)",
          }}
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary-500 text-white shadow hover:bg-primary-500/90 h-9 px-4 py-2 mt-4 w-full"
          disabled={!registrationType}
          onClick={handleNextRegister}
        >
          Let's get started
        </button>
      </div>

      {/* Modal */}
      {/* <!-- Modal Backdrop --> */}
      {detailModalShow && (
        <CustomModal
          show={detailModalShow}
          setShow={setDetailModalShow}
          title="Please provide additional Details"
        >
          {step === 0 && (
            <div className="grid gap-4">
              <div>
                <input
                  type="text"
                  value={formData.referralCode}
                  onChange={(e) =>
                    handleFormData("referralCode", e.target.value)
                  }
                  placeholder="Referral Code (optional)"
                  className="w-full border border-solid border-slate-300 rounded-[6px] px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                />
                <span className="text-red-600 text-xs font-medium mb-2">
                  {referralCodeErr}
                </span>
              </div>

              <div>
                <select
                  id="location"
                  name="location"
                  className="block w-full rounded-md  pl-3 pr-8 py-2.5 border border-solid border-slate-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  onChange={(e) =>
                    handleFormData("selectedCountry", e?.target?.value)
                  }
                >
                  <option>Choose a country</option>
                  {countries?.map((item: CountryItem) => {
                    return (
                      <option key={item?.id} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {timezoneList && (
                <div className="mt-2.5">
                  <select
                    id="timezone"
                    name="timezone"
                    className="block w-full rounded-md  pl-3 pr-8 py-2.5 border border-solid border-slate-300 sm:text-sm sm:leading-6 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    onChange={(e) =>
                      handleFormData("selectedTimezone", e?.target?.value)
                    }
                  >
                    <option>Choose a Timezone</option>
                    {timezoneList?.map((item: TimezoneItem) => {
                      return (
                        <option key={item?.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <button
                className="bg-black/80 disabled:bg-slate-600 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-black h-9 px-4 py-2"
                disabled={disableRegister}
              >
                {isOTPServiceCalled && (
                  <Loader2 className="animate-spin h-5 w-5 mr-3" />
                )}
                Continue
              </button>
            </div>
          )}
        </CustomModal>
      )}
    </>
  );
};

export default Register;
