import React, { useState, useEffect, useRef } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import googleIcon from "../assets/img/googleIcon.png";
import OtpInput from "react-otp-input";
import { OTPInput } from "input-otp";
import {
  SendRequestOTPService,
  LoginByOTPService,
  CheckGoogleLogin,
  CheckUserEmailIsRegistered,
  GenerateOTP,
  CheckUserExists,
  VerifyOTP,
  GetUserRole,
  validateToken,
  GetLocationDetails,
  SSOAuthLogin,
} from "../api/_request";
import { AlertCircle, Loader2 } from "lucide-react";
import { toast } from "sonner";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Slot } from "../components/OTP/Slot";
import { FakeDash } from "../components/OTP/FakeDash";
import EmailInput from "../components/inputs/emailInput";
import OtpVerifierInput from "../components/inputs/otpVerifierInput";
import RegisterButton from "../components/buttons/registerButton";
import { Button } from "@headlessui/react";
import Drawer from "../components/ui/drawer";
import {
  countriesJSON,
  formatTime,
  setCookieOnClientSide,
  setDataLayer,
  validEmailRegex,
} from "../lib/utils";
import AppleSignInButton from "react-apple-signin-auth";
import { useAppState } from "../context/AppStateContext";

const Login: React.FC = () => {
  const { state, dispatch } = useAppState();
  const OTPInputRef = useRef(null);

  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<FormErrors>({
    email: "",
    fullName: "",
    referralCode: "",
    otp: "",
  });
  const [googleLoginErrorMessage, setGoogleLoginErrorMessage] = useState<
    string | null
  >(null);
  const [disableGetOTP, setDisableGetOTP] = useState<boolean>(true);
  const [isOTPServiceCalled, setIsOTPServiceCalled] = useState<boolean>(false);
  const [isLoginServiceCalled, setIsLoginServiceCalled] =
    useState<boolean>(false);

  const [counter, setCounter] = useState<number>(30);
  const [verifyOTPCounter, setVerifyOTPCounter] = useState<number>(0);

  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);

  const [isAppleSDKLoaded, setIsAppleSDKLoaded] = useState(false);
  const [appLogo, setAppLogo] = useState(process.env.REACT_APP_UWC_LOGO);

  const navigate = useNavigate();
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");
  const utmSource = searchBarParams.get("utm_source");
  const utmMedium = searchBarParams.get("utm_medium");
  const utmCampaign = searchBarParams.get("utm_campaign");
  const utmID = searchBarParams.get("utm_id");

  //Load Apple SDK
  useEffect(() => {
    const checkAppleSDK = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: process.env.REACT_APP_APPLE_SERVICE_ID, // Replace with your Service ID
          scope: "name email",
          redirectURI: "https://accounts.unitedwecare.com", // Ensure this matches your Apple Developer configuration
          state: "optional-state",
          usePopup: true, // Use true to keep the user in the same page
        });
        setIsAppleSDKLoaded(true);
      } else {
        console.error("AppleID SDK not loaded");
      }
    };

    // Check if the Apple SDK is loaded
    checkAppleSDK();

    // Optionally, add an interval to keep checking until the SDK is loaded
    const interval = setInterval(() => {
      checkAppleSDK();
    }, 1000);

    // Clear the interval after 10 seconds
    setTimeout(() => {
      clearInterval(interval);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  //Check for email pattern
  useEffect(() => {
    if (validEmailRegex.test(email) && email !== "") {
      setDisableGetOTP(false);
    } else {
      setDisableGetOTP(true);
    }
  }, [email]);

  //Resend OTP Timer
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (step == 1) {
      if (counter > 0) {
        timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
      } else if (counter === 0) {
        //setResendAllowed(true); // Enable resend button when countdown reaches 0
      }
    }
    return () => clearTimeout(timer);
  }, [step, counter]);

  //Too many attempts Counter
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (verifyOTPCounter > 0) {
      timer = setTimeout(() => setVerifyOTPCounter(verifyOTPCounter - 1), 1000); // Decrease counter every second

      if (step == 0) {
        setErrors((prevData: any) => ({
          ...prevData,
          email: `Incorrect OTP. Please wait ${formatTime(
            verifyOTPCounter
          )} minutes before attempting again.`,
        }));
      }
      if (step == 1) {
        setErrors((prevData: any) => ({
          ...prevData,
          otp: `Incorrect OTP. Please wait ${formatTime(
            verifyOTPCounter
          )} minutes before attempting again.`,
        }));
      }
    } else if (verifyOTPCounter === 0) {
      //setResendAllowed(true); // Enable resend button when countdown reaches 0
      setErrors((prevData: any) => ({ ...prevData, otp: "" }));
    }

    return () => clearTimeout(timer);
  }, [verifyOTPCounter]);


  //Auto Send OTP Function
  /*useEffect(() => {
    if (otp.length === 4) {
      handleLogin();
    }
  }, [otp]);*/

  const handleResendOTP = async (e: any) => {
    try {
      e.preventDefault();

      const resendOTPStatus = await GenerateOTP(
        email?.toLocaleLowerCase(),
        window.location.host
      );
      if (resendOTPStatus?.status == 200) {
        setCounter(30);
        setErrors((prevData: any) => ({
          ...prevData,
          otp: "",
        }));
        toast.success("OTP sent successfully!");
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const handleEmail = async (e: any) => {
    try {
      e.preventDefault();

      setErrors((prevData: any) => ({
        ...prevData,
        email: "",
      }));
      setDisableGetOTP(true);
      setIsOTPServiceCalled(true);

      const userExists = await CheckUserExists(email?.toLocaleLowerCase());
      if (userExists) {
        const resendOTPStatus = await GenerateOTP(
          email?.toLocaleLowerCase(),
          window.location.host
        );
        //console.log("resendOTPStatus", resendOTPStatus?.data);
        if (resendOTPStatus?.data?.code == 200) {
          setStep(1);

          setDataLayer({
            redirectURL,
            type: "COPILOT_USER",
            regRef: "uwc_login_01",
            method: "email",
            event: "uwc_login",
            provider: email.toLocaleLowerCase()?.split("@")[1],
          });
        } else if (+resendOTPStatus?.data?.code === 429) {
          setErrors((prevData: any) => ({
            ...prevData,
            email: resendOTPStatus?.data?.message,
          }));
          if (+resendOTPStatus?.data?.data?.retry_after) {
            // setOpenWarningDialog(true);
            setVerifyOTPCounter(+resendOTPStatus?.data?.data?.retry_after);
          }
        } else {
          setErrors((prevData: any) => ({
            ...prevData,
            email: resendOTPStatus?.data?.message,
          }));
          //toast.error(resendOTPStatus?.data?.message);
        }
      } else {
        setErrors((prevData: any) => ({
          ...prevData,
          email: "Email address not found!",
        }));
        toast.warning("Email address not found!");
      }
    } catch (error) {
      //console.error("Registration failed:", error);
      toast.error("Something went wrong! Please try again.");
      //alert("Registration failed. Please try again.");
    } finally {
      setIsOTPServiceCalled(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenWarningDialog(false);
  };

  const handleLogin = async (e?: any) => {
    e?.preventDefault(); // Prevent default form submission behavior
    setIsLoginServiceCalled(true);

    try {
      // Use await to wait for the response
      const res = await VerifyOTP(email?.toLocaleLowerCase(), +otp);

      if (+res?.data?.code === 200) {
        const TOKEN = res?.data?.data;
        const USERID = null;
        const USERROLE = res?.data?.datas?.role;
        const USERJWT = res?.data?.datas?.jwt;

        const TOKENVALIDATIONDATA = await validateToken(TOKEN);
        let userName = null;
        if (+TOKENVALIDATIONDATA?.ResponseCode === 200) {
          userName =
            TOKENVALIDATIONDATA?.data?.userdetails?.Name?.split(" ")[0];
        } else {
          console.error("Unable to fetch user name!");
        }

        const setCookieBody: SetCookieInterface = {
          token: TOKEN,
          userID: USERID,
          userDataJwt: USERJWT,
          utmCampaign,
          utmMedium,
          redirectURL,
          type: "USER",
          regRef: "uwc_login",
          method: "email",
          event: "uwc_login",
          authUserRole: USERROLE,
          provider: email?.split("@")[1],
          displayName: userName ? userName : email?.split("@")[0],
        };
        // toast.success("OTP verified successfully!");

        setCookieOnClientSide(setCookieBody);
      } else if (+res?.data?.code === 401) {
        //toast.error(res?.data.message);

        setErrors((prevData: any) => ({
          ...prevData,
          otp: res?.data?.message,
        }));

        if (+res?.data?.data?.retry_after) {
          setCounter(30);
          setVerifyOTPCounter(+res?.data?.data?.retry_after);
          // setOpenWarningDialog(true);
        }

        setIsLoginServiceCalled(false);
      } else if (+res?.data?.code === 429) {
        toast.error(
          "Oops! Something went wrong. Please try requesting the OTP again later."
        );
        setIsLoginServiceCalled(false);
      }
    } catch (error) {
      // Handle errors more gracefully
      console.error("Login failed:", error);
      toast.error("Something went wrong! Please try again.");
      setIsLoginServiceCalled(false);
    } finally {
      // This block runs regardless of the try/catch result

      setOtp("");
    }
  };

  const onGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      // Ensure jwtDecode is called safely
      setIsLoginServiceCalled(true);
      // console.log("Google credentialResponse", jwtDecode(credentialResponse?.credential));
      const { credential, clientId, select_by } = credentialResponse;
      const { email, name } = jwtDecode<DecodedToken>(
        credentialResponse?.credential
      );

      if (!credential) {
        //throw new Error("Token decoding failed");
        toast.error("Oops!! Please try again.");
        setIsLoginServiceCalled(false);
      }

      // console.log("userExists", userExists);

      const googleLoginReqBody = {
        credential,
        clientId,
        select_by,
        //id_token: credential,
      };
      const googleLoginResponse = await SSOAuthLogin(
        googleLoginReqBody,
        "user",
        "google"
      );
      const {
        code,
        data: { jwt, role, session_token },
      } = googleLoginResponse;
      const { user_id } = jwtDecode<any>(jwt);

      if (+code === 200) {
        const setCookieBody: SetCookieInterface = {
          token: session_token,
          userID: user_id,
          userDataJwt: jwt,
          utmCampaign,
          utmMedium,
          redirectURL,
          type: "USER",
          regRef: "uwc_user_login",
          method: "google",
          event: "uwc_login",
          authUserRole: role,
          provider: email?.split("@")[1],
          displayName: name?.split(" ")[0],
        };

        setCookieOnClientSide(setCookieBody);
      }
    } catch (error) {
      console.error("Error in Google login process:", error);
      toast.error("Oops!! Please try again.");
      setIsLoginServiceCalled(false);
      // Handle any errors that occurred during the login process
    }
  };

  const handleFormData = (type: string, value: string) => {
    setEmail(value);
  };

  const handleAppleLogin = async () => {
    if (isAppleSDKLoaded && window.AppleID && window.AppleID.auth) {
      try {
        const response = await window.AppleID.auth.signIn();

        const { authorization } = response;
        alert(`authorization: ${authorization}`);
        console.log("authorization", authorization);
      } catch (error) {
        console.log("ERROR", error);
      }
    } else {
      console.error("AppleID SDK not loaded or auth not initialized");
    }
  };

  const handleAppleSuccess = (data: any) => {
    console.log("APPLE credentialResponse", data);
    const {
      authorization: { id_token },
    } = data;

    const newDecodedToken = jwtDecode(id_token);
    console.log("newDecodedToken", newDecodedToken);
  };

  const handleAppleError = (err: any) => {
    console.log("ERR", err);
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => console.log(codeResponse),
    flow: "auth-code",
  });

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.48)` // Adjust the alpha value as needed
    : "";
  return (
    <div className="w-full max-w-[350px] Login--form">
      {/* Registration is hidden for now */}
      <RegisterButton redirectURL={redirectURL} type="FLOATING" />

      <img
        src={state?.appLogoUrl || process.env.REACT_APP_UWC_LOGO}
        alt="logo"
        width={150}
        className="mx-auto mb-8 xl:hidden"
      />
      <h1 className="text-2xl font-semibold tracking-tight text-center">
        Login to your account!
      </h1>

      <div className="grid gap-6">
        {/*googleLoginErrorMessage !== null && (
          <div className="rounded-md bg-red-100 p-4 mt-8">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertCircle
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-1">
                <h3 className="text-xs font-medium text-red-800">
                  {googleLoginErrorMessage}
                </h3>
              </div>
            </div>
          </div>
        )*/}

        {step == 0 && (
          <>
            <form id={"login_email"} className="flex flex-col gap-2 mt-5">
              <EmailInput
                value={email}
                handleFormData={handleFormData}
                error={errors?.email}
                setErrors={setErrors}
              />
              {verifyOTPCounter === 0 && (
                <button
                  style={{
                    backgroundColor: disableGetOTP
                      ? rgbaColor
                      : "var(--primary)",
                  }}
                  onClick={handleEmail}
                  disabled={disableGetOTP}
                  className="sendOTP"
                >
                  {isOTPServiceCalled && (
                    <Loader2 className="animate-spin h-5 w-5 mr-3" />
                  )}
                  Continue
                </button>
              )}
            </form>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t"></span>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white px-2 text-slate-400 font-medium">
                  Or continue with
                </span>
              </div>
            </div>
            {/* bg-[#202124] hover:bg-[#555658]  rounded-lg h-11 py-0.5*/}
            <div className="grid gap-2">
              <div className="relative pl-2">
                <GoogleLogin
                  onSuccess={onGoogleLoginSuccess}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  //type="icon"
                  theme="outline"
                  shape="rectangular"
                  size="large"
                  text="signin"
                  width={"343"}
                  ux_mode="popup"
                  logo_alignment="center"
                />
              </div>

              {/* <AppleSignInButton
                className={"h-[40px]"}
                authOptions={{
                  clientId: "com.unitedwecare.accounts",
                  scope: "name email",
                  redirectURI: "https://accounts-stage.unitedwecare.com/",
                }}
                onSuccess={handleAppleSuccess}
                onError={handleAppleError}
                uiType={"dark"}
              /> */}
            </div>

            <div className="text-sm text-center text-slate-900 font-semibold">
              Don't have an account? &nbsp;
              <RegisterButton redirectURL={redirectURL} type="INLINE" />
            </div>
          </>
        )}

        {step == 1 && (
          <div className="grid gap-6 pb-24">
            <h6 className="mt-4 text-center text-base">
              Please enter One Time Password (OTP) sent to your email.
            </h6>
            <div className="flex flex-col gap-5 mt-5" ref={OTPInputRef}>
              <OtpVerifierInput
                otp={otp}
                setOtp={setOtp}
                error={errors.otp}
                setErrors={setErrors}
                counter={verifyOTPCounter}
                id={"login_otp"}
              />

              {+verifyOTPCounter == 0 && (
                <div className="mt-5 flex flex-col gap-3">
                  {counter > 0 ? (
                    <span className="flex-1 text-sm font-medium text-center text-slate-400 h-9 px-4 py-2">
                      Resend OTP in {counter}s
                    </span>
                  ) : (
                    <button className="resendOTP" onClick={handleResendOTP}>
                      Resend OTP
                    </button>
                  )}

                  <button
                    style={{
                      backgroundColor:
                        otp.length !== 4 || isLoginServiceCalled
                          ? rgbaColor
                          : "var(--primary)",
                    }}
                    onClick={handleLogin}
                    disabled={otp.length !== 4 || isLoginServiceCalled}
                    className="text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-primary-600 h-9 px-4 py-2"
                  >
                    {isLoginServiceCalled && (
                      <Loader2 className="animate-spin h-5 w-5 mr-3" />
                    )}
                    Verify OTP
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Drawer
        isOpen={openWarningDialog}
        onClose={handleCloseDialog}
        counter={verifyOTPCounter}
      />
      {isLoginServiceCalled && (
        <div className="fixed w-full h-full top-0 left-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
          <Loader2 className="animate-spin h-10 w-10 text-white" />
        </div>
      )}
    </div>
  );
};

export default Login;
