const TncAgreeSwitch = ({
  handleFormData,
  isTermsAccepted,
  userCountry,
}: any) => {
  let termsLink, privacyPolicyLink;
  const isAskSam =
    window.location.host.includes(".ask-sam.ai") ||
    window.location.host.includes(".asksam.com.au");

  if (isAskSam) {
    termsLink = "https://legal.asksam.com.au/ai-use-policy";

    privacyPolicyLink = "https://legal.asksam.com.au/au/privacy-policy";
  } else {
    if (userCountry == "IN" || userCountry == "US" || userCountry == "AU") {
      termsLink = `https://legal.unitedwecare.com/${userCountry.toLocaleLowerCase()}/terms-and-conditions/`;
      privacyPolicyLink = `https://legal.unitedwecare.com/${userCountry.toLocaleLowerCase()}/privacy-policy/`;
    } else {
      termsLink = `https://legal.unitedwecare.com/us/terms-and-conditions/`;
      privacyPolicyLink = `https://legal.unitedwecare.com/us/privacy-policy/`;
    }
  }

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.48)` // Adjust the alpha value as needed
    : "";
  return (
    <div className="flex items-center justify-between gap-2 bg-slate-200/60 rounded-lg py-4 px-3 border border-solid border-slate-200/80">
      <span className="text-sm" id="terms-and-conditions">
        <div className="text-sm text-gray-900">
          I agree with{" "}
          <a
            className="text-blue-600 no-underline hover:underline underline-offset-2 cursor-pointer"
            href={termsLink}
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            className="text-blue-600 no-underline hover:underline underline-offset-2 cursor-pointer"
            href={privacyPolicyLink}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </span>

      {/* <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" --> */}
      <button
        type="button"
        name="isTermsAccepted"
        style={{
          backgroundColor: isTermsAccepted ? "var(--primary)" : rgbaColor,
        }}
        className={`relative inline-flex h-[20px] w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none `}
        role="switch"
        aria-checked="false"
        aria-labelledby="terms-and-conditions"
        onClick={() => handleFormData("isTermsAccepted", !isTermsAccepted)}
      >
        {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
        <span
          aria-hidden="true"
          className={`${
            isTermsAccepted ? "translate-x-5" : "translate-x-0"
          }  pointer-events-none inline-block h-[16px] w-[16px] aspect-square transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
        ></span>
      </button>
    </div>
  );
};

export default TncAgreeSwitch;
