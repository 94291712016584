import React from "react";
import UserRegisterForm from "../components/UserRegisterForm";

import LoginButton from "../components/buttons/loginButton";
import { useAppState } from "../context/AppStateContext";

const RegisterUser = () => {
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");

  const { state, dispatch } = useAppState();

  return (
    <div className="w-full max-w-[355px] px-1  Login--form ">
      <LoginButton redirectURL={redirectURL} type="FLOATING" />
      <img
        src={state?.appLogoUrl || process.env.REACT_APP_UWC_LOGO}
        alt="logo"
        width={150}
        className="mx-auto mb-4 xl:hidden"
      />
      <h1 className="text-2xl font-semibold tracking-tight text-center">
        Create Account
      </h1>
      {/* <p className="text-sm text-slate-500 font-medium text-center">
          Sign up with your email address
        </p> */}

      <UserRegisterForm />
    </div>
  );
};

export default RegisterUser;
