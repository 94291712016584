// GTMComponent.js
import React, { useEffect, useState } from "react";

const GTMComponent = () => {
  const [gtmId, setGtmId] = useState<string | null>(null);
  const [gId, setGId] = useState<string | null>(null);

  useEffect(() => {
    const hostname = window.location.hostname;

    // Determine GTM ID based on subdomain
    if (
      hostname.includes(".asksam.com.au") ||
      hostname.includes(".ask-sam.ai")
    ) {
      setGtmId("GTM-MVKNCM4H");
      setGId("G-6R9WK18T5N");
    } else {
      setGtmId("GTM-M286P4S");
      setGId("G-28HL9LHXGG");
    }
  }, []);

  useEffect(() => {
    if (gtmId && gId) {
      // Inject the GA script dynamically
      const gaScript = document.createElement("script");
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gId}`;
      gaScript.async = true;
      document.head.appendChild(gaScript);

      // Initialize dataLayer for GA
      (window as any).dataLayer = (window as any).dataLayer || [];

      // Convert `gtag` function to a function expression to avoid block-scoping error
      const gtag = (...args: any[]) => {
        (window as any).dataLayer.push(args);
      };

      gtag("js", new Date());
      gtag("config", gId);

      // Inject the GTM script dynamically
      const gtmScript = document.createElement("script");
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);

      // Initialize dataLayer for GTM
      (window as any).dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
    }
  }, [gtmId, gId]);

  return (
    <>
      {/* Conditionally render noscript for GTM with appropriate ID */}
      {gtmId && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            title="gtm-noscript"
          ></iframe>
        </noscript>
      )}
    </>
  );
};

export default GTMComponent;
