import { useEffect } from "react";
import { isHostMatch } from "../lib/utils";

const useFavicon = (faviconUrl: string) => {
  
  useEffect(() => {
    const favicon = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement;

    if (favicon) {
      favicon.href = faviconUrl;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = faviconUrl;
      document.head.appendChild(newFavicon);
    }
  }, [faviconUrl, isHostMatch]);
};

export default useFavicon;
