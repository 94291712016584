export enum ActionTypes {
  SET_EMAIL = "SET_EMAIL",
  SET_FULLNAME = "SET_FULLNAME",
  SET_FIRST_NAME = "SET_FIRST_NAME",
  SET_LAST_NAME = "SET_LAST_NAME",
  SET_REFERRAL_CODE = "SET_REFERRAL_CODE",
  SET_COUNTRY = "SET_COUNTRY",
  SET_COUNTRY_ID = "SET_COUNTRY_ID",
  SET_COUNTRY_FULL_NAME = "SET_COUNTRY_FULL_NAME",
  SET_TIMEZONE = "SET_TIMEZONE",
  SET_ISTERMS_ACCEPTED = "SET_ISTERMS_ACCEPTED",
  SET_PHONE_CODE = "SET_PHONE_CODE",
  SET_PHONE_NUMBER = "SET_PHONE_NUMBER",
  SET_USER_TYPE = "SET_USER_TYPE",
  SET_USER_ID = "SET_USER_ID",
  SET_INSTITUTION_ID = "SET_INSTITUTION_ID",
  SET_REFERENCE_ID = "SET_REFERENCE_ID",
  SET_GROUP_ID = "SET_GROUP_ID",
  SET_DEVICE_TYPE = "SET_DEVICE_TYPE",
  SET_DEVICE_ID = "SET_DEVICE_ID",
  SET_SPECIALIZATION = "SET_SPECIALIZATION",
  UPDATE_FORM_DATA = "UPDATE_FORM_DATA",

  SET_APP_LOGO = "SET_APP_LOGO",
}
